import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, Observable, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import * as moment from "moment";
import * as retailerActions from "../retailer/retailer.actions";
import * as customerActions from "../customer/customer.actions";
import * as adminActions from "../admin/admin.actions";
import * as fromAdmin from "../admin/admin.reducer";
import * as fromRetailer from "../retailer/retailer.reducer";
import * as fromCustomer from "../customer/customer.reducer";
import { AuthService } from "src/app/services/auth.service";
import { Store } from "@ngrx/store";
import { Statement } from "../models/models";
import { STATEMENT_DATE_FORMAT } from "../utils";
import firebase from "firebase";
import { environment } from "../../environments/environment.prod";
import saveAs from "save-as";
import axios from "axios";
import { NotifyService } from "../shared/notify.service";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
})
export class InvoicesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  customers$: Observable<any>;
  public user: User = null;
  public invoicesData$: Observable<any>;
  public name: string;
  target: string;
  userId: string;
  filter: string;
  loading = false;
  selectedInvoices: Statement[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private adminStore: Store<fromAdmin.State>,
    private retailerStore: Store<fromRetailer.State>,
    private customerStore: Store<fromCustomer.State>,
    private notify: NotifyService
  ) {}

  ngOnInit() {
    this.auth.user$
      .pipe(
        tap((user) => {
          this.retailerStore.dispatch(new retailerActions.Query());
          this.customerStore.dispatch(new customerActions.Query(user));
          this.adminStore.dispatch(new adminActions.Query());
        })
      )
      .subscribe((user) => {
        if (user.roles.customer) {
          this.router.navigate(["/landing"]);
        }
        this.user = user;
      });

    this.route.paramMap
      .pipe(
        map((params) => {
          return {
            id: params.get("id"),
            target: params.get("target"),
          };
        })
      )
      .subscribe((params) => {
        this.target = params.target;
        this.userId = params.id;
      });

    this.route.queryParamMap
      .pipe(
        map((params) => {
          return {
            filter: params.get("filter"),
          };
        })
      )
      .subscribe((params) => {
        this.filter = params.filter || moment().format(STATEMENT_DATE_FORMAT);
        this.setInvoices();
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private setInvoices() {
    if (this.target === "retailer") {
      const retailerStore$ = this.retailerStore
        .select(fromRetailer.selectAll)
        .pipe(
          map((retailers) => retailers.find((r) => r.id === this.userId))
        );
      const customerStore$ = this.customerStore.select(fromCustomer.selectAll);
      this.invoicesData$ = combineLatest([retailerStore$, customerStore$]).pipe(
        map(([retailer, customers]) => {
          if (retailer) {
            this.name = retailer.name;
            return this.setOrderedInvoices(
              retailer.invoices.map((i) => {
                // Ensure customerRef exists before accessing id
                if (i.customerRef && i.customerRef.id) {
                  const customer = customers.find(
                    (c) => c.id === i.customerRef.id
                  );
                  i.name = customer ? customer.name : "Loading Customer name...";
                } else {
                  i.name = "Unknown Customer";
                }
                return i;
              })
            );
          }
          return [];
        })
      );
    } else if (this.target === "admin") {
      const adminStore$ = this.adminStore.select(fromAdmin.selectAll);
      const retailerStore$ = this.retailerStore.select(fromRetailer.selectAll);
      this.invoicesData$ = combineLatest([adminStore$, retailerStore$]).pipe(
        map(([admins, retailers]) => {
          this.name = "VaultWrx";
          const admin = admins && admins.length ? admins[0] : null;
          if (admin) {
            return this.setOrderedInvoices(
              admin.invoices.map((i) => {
                // Ensure retailerRef exists before accessing id
                if (i.retailerRef && i.retailerRef.id) {
                  const retailer = retailers.find(
                    (r) => r.id === i.retailerRef.id
                  );
                  i.name = retailer ? retailer.name : "Unknown Retailer";
                } else {
                  i.name = "Unknown Retailer";
                }
                return i;
              })
            );
          }
          return [];
        })
      );
    }
  }

  private setOrderedInvoices(invoices: Statement[]): Statement[] {
    let orderedInvoices: Statement[] = [];
    if (invoices) {
      orderedInvoices = invoices
        .filter((i) =>
          moment(i.date, STATEMENT_DATE_FORMAT).isSame(
            moment(this.filter, STATEMENT_DATE_FORMAT)
          )
        )
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      for (let i = 0; i < orderedInvoices.length; i++) {
        const invoice = orderedInvoices[i];
        // Derive location from invoice path
        invoice.location = invoice.path.split(":")[1]?.split("-")[0];
        // Update invoice path with URL from Firebase Storage
        firebase
          .storage()
          .refFromURL(`gs://${environment.domain}.appspot.com/${invoice.path}`)
          .getDownloadURL()
          .then((url) => {
            invoice.path = url;
          })
          .catch((err) => {
            console.error(
              `Error fetching download URL for invoice "${invoice.name}" with path "${invoice.path}":`,
              err
            );
            // Optionally, set a placeholder URL or flag to indicate the file is inaccessible.
            invoice.path = "";
          });
      }
    }
    return orderedInvoices;
  }

  public onUpdateSelectedInvoices({ invoices }) {
    this.selectedInvoices = invoices;
  }

  public downloadSelectedInvoices() {
    this.loading = true;
    this.selectedInvoices.forEach((statement, i) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = () => {
        saveAs(xhr.response, `${statement.name}.pdf`);
      };
      xhr.open("GET", statement.path);
      xhr.send();
      if (i === this.selectedInvoices.length - 1) {
        this.loading = false;
      }
    });
  }

  public generateStatements() {
    const now = new Date();
    this.loading = true;
    const roles = {
      admin: this.user.roles?.admin ?? false,
      customer: this.user.roles?.customer ?? false,
      retailer: this.user.roles?.retailer ?? false,
    };
    const payload: {
      retailerId?: string;
      customerId?: string;
      date: string;
      roles: typeof roles;
    } = {
      date: moment(this.filter, "MMMM YYYY")
        .date(now.getDate())
        .format("MM/DD/YYYY"),
      roles: roles,
      retailerId: "admin",
      customerId: "customer",
    };

    if (roles.retailer && this.user.retailerRef) {
      payload.retailerId = this.user.retailerRef.id;
    }
    if (roles.customer && this.user.customerRef) {
      payload.customerId = this.user.customerRef.id;
    }

    axios
      .post(
        `https://us-central1-${environment.domain}.cloudfunctions.net/statements`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setInvoices();
        this.notify.update("Invoices updated successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        this.notify.update(err.message, "danger");
        this.loading = false;
      });
  }
}
